import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Step2({ data = { formats: {}, frequency: 0 }, onTotalCountChange, updateData }) {
  const { formats, frequency } = data;
  const [categories, setCategories] = useState([]); // State to store categories
  const [loading, setLoading] = useState(true); // State to handle loading
  // const backendUrl = "http://localhost:8080";
  const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";

  const totalCount = Object.values(formats).reduce((sum, count) => sum + count, 0); // Calculate the total count

  useEffect(() => {
    onTotalCountChange(totalCount); // Notify parent component about the total count
  }, [totalCount, onTotalCountChange]);

  useEffect(() => {
    // Fetch categories from backend
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${backendUrl}/queries/data_business_info_health`);

        // Extract categories and split by commas
        const categoryData = response.data
          .map((item) => item.categories.split(",").map((category) => category.trim())) // Split and trim
          .flat(); // Flatten the array

        setCategories(categoryData); // Set categories as a flat array

        // Save categories and initial formats in parent state
        updateData("categories", categoryData); // Save categories to parent state
        const initialFormats = categoryData.reduce((acc, category) => {
          acc[category] = 0; // Initialize each category with a count of 0
          return acc;
        }, {});
        updateData("formats", initialFormats); // Save formats to parent state

        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, [updateData]);

  const handleAdd = (category) => {
    if (totalCount < frequency) {
      const updatedFormats = { ...formats, [category]: (formats[category] || 0) + 1 };
      updateData("formats", updatedFormats); // Update formats in parent state
    }
  };

  const handleRemove = (category) => {
    if (formats[category] > 0) {
      const updatedFormats = { ...formats, [category]: formats[category] - 1 };
      updateData("formats", updatedFormats); // Update formats in parent state
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p>Loading categories...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-gray-100 pt-12">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">Distribute Your Categories</h1>
      <p className="text-lg mb-8 text-gray-700">
        Total categories selected: {totalCount}/{frequency}
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
        {categories.map((category) => (
          <div
            key={category}
            className="w-56 h-56 bg-white text-black rounded-lg shadow-lg flex flex-col items-center justify-center"
          >
            <h2 className="text-2xl font-bold capitalize">{category}</h2>
            <p className="text-3xl font-extrabold my-4">{formats[category]}</p>
            <div className="flex space-x-4">
              <button
                onClick={() => handleRemove(category)}
                className={`w-12 h-12 bg-teal-500 text-white font-bold rounded-full hover:bg-teal-600 transition ${
                  formats[category] === 0 && "opacity-50 cursor-not-allowed"
                }`}
                disabled={formats[category] === 0}
              >
                -
              </button>
              <button
                onClick={() => handleAdd(category)}
                className={`w-12 h-12 bg-teal-500 text-white font-bold rounded-full hover:bg-teal-600 transition ${
                  totalCount >= frequency && "opacity-50 cursor-not-allowed"
                }`}
                disabled={totalCount >= frequency}
              >
                +
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
