import React from "react";
import "./termsofservice.scss";
import logo from "../../assets/socialoha_logo.png";

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container">
      <header>
        <nav>
          <div className="logo">
            <img src={logo} alt="Logo" className="logo" />
            <h1>SociAloha</h1>
          </div>
          <ul className="nav-links">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/feedback-survey">Survey</a>
            </li>
            <li>
              <a href="/terms-of-service">Terms of Service</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            <li>
              <a href="/SignUp">Try It Out</a>
            </li>
          </ul>
        </nav>
      </header>

      <main>
        <section id="terms-of-service">
          <h2>Terms of Service</h2>
          <p>Effective Date: June 5, 2024</p>
          <p>Contact Information: socialohamail@gmail.com, 236-869-2318</p>
          <h3>1. Introduction</h3>
          <p>
            Welcome to Swell! These Terms of Service ("Terms") govern your
            use of our social media management services. By accessing or using
            Swell, you agree to comply with and be bound by these Terms. If
            you do not agree to these Terms, please do not use our services.
          </p>
          <h3>2. Ownership</h3>
          <p>
            For information on Swell’s ownership, please contact us at
            Swellmail@gmail.com.
          </p>
          <h3>3. Freemium Service</h3>
          <p>
            Swell’s services are offered under the freemium model, providing
            basic services for free, while more advanced or special services are
            subject to a fee, as outlined in our pricing plans.
          </p>
          <h3>4. Veracity of Data Provided</h3>
          <p>
            Users are solely responsible for ensuring that the data provided is
            up-to-date, accurate, and truthful. Swell declines any
            responsibility for the accuracy of the data provided by users.
          </p>
          <h3>5. Use of SociAloha</h3>
          <h4>5.1. Language:</h4>
          <p>The website content is available in English.</p>
          <h4>5.2. Proper Use:</h4>
          <p>
            Users agree to use the resources provided by Swell responsibly,
            complying with current laws and regulations, and not causing damage
            to the physical or logical systems of Swell or third parties.
          </p>
          <h4>5.3. User Participation:</h4>
          <p>
            Users may not post or transmit any content that is defamatory,
            obscene, abusive, or otherwise objectionable. Swell reserves the
            right to remove any content that violates these terms.
          </p>
          <h3>6. Interruptions in Service</h3>
          <p>
            Swell does not guarantee uninterrupted access to the service. We
            will strive to address any issues promptly but are not responsible
            for interruptions caused by factors beyond our control.
          </p>
          <h3>7. Disclaimer</h3>
          <p>
            Swell is provided "as is" without warranties of any kind, either
            express or implied. Swell will not be liable for any direct,
            indirect, incidental, or consequential damages arising from the use
            or inability to use the service.
          </p>
          <h3>8. Intellectual Property</h3>
          <p>
            All content and materials on Swell, including text, images,
            logos, and software, are the intellectual property of Swell and
            are protected by applicable copyright and trademark laws. Users are
            prohibited from reproducing, distributing, or creating derivative
            works from this content without express written permission.
          </p>
          <h3>9. AI-Generated Content</h3>
          <p>
            Any content generated by Swell's AI during your use of the
            service is owned by you. However, by using Swell, you grant
            Swell a non-exclusive, royalty-free license to use, reproduce,
            and display such content for the purpose of providing and improving
            the service.
          </p>
          <h3>10. Links Policy</h3>
          <h4>10.1. Links from Other Websites:</h4>
          <p>
            If you establish a link to Swell from another website, you must
            ensure that the link does not imply any relationship, endorsement,
            or approval by Swell. The use of deep-links, IMG or image links,
            or frames is not allowed without prior authorization.
          </p>
          <h4>10.2. Links to Other Websites:</h4>
          <p>
            Swell may contain links to third-party websites. We do not
            control or endorse the content of these websites and are not
            responsible for their content or availability.
          </p>
          <h3>11. Breach and Right of Exclusion</h3>
          <p>
            Any breach of these Terms may result in the suspension or
            termination of the user’s access to Swell. SociAloha reserves
            the right to take legal action to protect its interests.
          </p>
          <h3>12. Personal Data Protection</h3>
          <p>
            Swell is committed to protecting your personal data. Please
            refer to our Privacy Policy for detailed information on how we
            collect, use, and protect your personal data.
          </p>
          <h3>13. Modification of Terms</h3>
          <p>
            Swell reserves the right to modify these Terms at any time.
            Changes will be published on our website and will be effective upon
            posting. Your continued use of the service after any changes
            indicates your acceptance of the new Terms.
          </p>
          <h3>14. Governing Law</h3>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of British Columbia, Canada, without regard to its conflict of
            law principles. Any legal action or proceeding related to the use of
            Swell shall be brought exclusively in the courts located in
            British Columbia.
          </p>
          <h3>15. Contact Us</h3>
          <p>
            If you have any questions about these Terms, please contact us at
            Swellmail@gmail.com or 236-869-2318.
          </p>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 SociAloha. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default TermsOfService;
