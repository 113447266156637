import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PostPopup from "./postpopup"; // Import the popup component

// const backendUrl = "http://localhost:8080";
const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";
const statusColors = {
  Pending: "bg-red-100 text-red-700",
  Scheduled: "bg-yellow-100 text-yellow-700",
  Posted: "bg-green-100 text-green-700",
};

const typeColors = {
  Story: "bg-blue-500",
  Post: "bg-green-500",
  Carousel: "bg-purple-500",
  Reel: "bg-orange-500",
};

const ToDoList = () => {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null); // For managing popup
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlannedPosts = async () => {
      try {
        const response = await axios.get(`${backendUrl}/queries/get_all_planned_posts`);
        const sortedPosts = response.data.sort((a, b) =>
          moment(a.scheduled_time).valueOf() - moment(b.scheduled_time).valueOf()
        );
        setPosts(sortedPosts);
      } catch (error) {
        console.error("Error fetching planned posts:", error);
      }
    };
    fetchPlannedPosts();
  }, []);

  const formatDueDate = (date) => {
    const now = moment();
    const dueDate = moment(date);

    if (dueDate.isSame(now, "day")) {
      return `Today at ${dueDate.format("h:mm A")}`;
    } else if (dueDate.isSame(now.add(1, "day"), "day")) {
      return `Tomorrow at ${dueDate.format("h:mm A")}`;
    } else if (dueDate.isSame(now, "week")) {
      return `${dueDate.format("dddd [at] h:mm A")}`;
    } else {
      return dueDate.format("MMM D, YYYY [at] h:mm A");
    }
  };

  const handleEdit = (post) => {
    setSelectedPost(post); // Open popup with selected post
  };

  const handleUpdate = (updatedPost) => {
    setPosts((prev) =>
      prev.map((post) =>
        post.planned_post_id === updatedPost.planned_post_id ? updatedPost : post
      )
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 p-8 font-sans">
      <div className="bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold mb-6 text-gray-800">To-Do Posts</h1>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="p-4 text-gray-600 font-medium">Category</th>
                <th className="p-4 text-gray-600 font-medium">Topic</th>
                <th className="p-4 text-gray-600 font-medium">Due Date</th>
                <th className="p-4 text-gray-600 font-medium">Status</th>
                <th className="p-4 text-gray-600 font-medium"></th>
              </tr>
            </thead>
            <tbody>
              {posts.map((post) => (
                <tr key={post.planned_post_id} className="border-b hover:bg-gray-50">
                  <td className="p-4 text-gray-700 font-medium">{post.post_type}</td>
                  <td className="p-4 text-gray-700 font-medium">
                    {post.post_description || "No Topic"}
                  </td>
                  <td className="p-4 text-gray-500 font-medium">
                    {formatDueDate(post.scheduled_time)}
                  </td>
                  <td className="p-4">
                    <span
                      className={`px-3 py-1 rounded-full text-sm font-semibold ${statusColors[post.status]}`}
                    >
                      {post.status}
                    </span>
                  </td>
                  <td className="p-4 text-gray-500">
                    <button
                      onClick={() => handleEdit(post)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FaEdit size={12} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {selectedPost && (
        <PostPopup
          post={selectedPost}
          onClose={() => setSelectedPost(null)}
          onUpdate={handleUpdate}
          navigate={navigate}
        />
      )}
    </div>
  );
};

export default ToDoList;
