import React, { useState, useEffect, useRef } from "react";
import "./home.scss"; // Import the SCSS file
import { useAuth } from "../../contexts/AuthContext"; // Adjust the path as needed
import { useNavigate } from "react-router-dom";
import logo from "../../assets/socialoha_logo.png";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";
// const backendUrl = 'http://localhost:8080'

function Home() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [conversation, setConversation] = useState([]);
  const [currentInput, setCurrentInput] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(
    "🏷️ What is the name you want associated with your brand?"
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const conversationEndRef = useRef(null);
  const inputRef = useRef(null);
  const [formData, setFormData] = useState({
    brand_name: "",
    categories: "",
    target_customer: "",
    tone_voice: "",
    content_type: "",
    email: currentUser ? currentUser.email : "",
  });
  
  const [editIndex, setEditIndex] = useState(null);

  const questions = [
    "🏷️ What is the name you want associated with your brand?",
    "📂 What categories do you want to focus on? (Nutrition, Wellness, Fitness, Health, Other - please specify)",
    "🎯 Describe your target customer in your own words.",
    "🗣️ What tone or voice do you prefer for your content? (friendly, professional, humorous, inspiring)",
    "📄 What type of content would you be interested in us exploring?",
  ];
  

  const handleInputChange = (e) => {
    if (editIndex === null) {
      setCurrentInput(e.target.value);
    }
  };

  const handleNext = () => {
    if (currentInput.trim() === "") return;

    if (editIndex !== null) {
      const updatedConversation = [...conversation];
      updatedConversation[editIndex] = {
        question: conversation[editIndex].question,
        answer: currentInput,
      };
      setConversation(updatedConversation);

      updateFormData(editIndex, currentInput);

      setEditIndex(null);
    } else {
      setConversation((prev) => [
        ...prev,
        { question: currentQuestion, answer: currentInput },
      ]);

      updateFormData(currentQuestionIndex, currentInput);

      setCurrentQuestionIndex((prev) => prev + 1);
      setCurrentQuestion(questions[currentQuestionIndex + 1]);
    }

    setCurrentInput("");
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setCurrentInput(conversation[index].answer);
  };

  const updateFormData = (index, input) => {
    switch (index) {
      case 0:
        setFormData((prevFormData) => ({
          ...prevFormData,
          brand_name: input,
        }));
        break;
      case 1:
        setFormData((prevFormData) => ({
          ...prevFormData,
          categories: input,
        }));
        break;
      case 2:
        setFormData((prevFormData) => ({
          ...prevFormData,
          target_customer: input,
        }));
        break;
      case 3:
        setFormData((prevFormData) => ({
          ...prevFormData,
          tone_voice: input,
        }));
        break;
      case 4:
        setFormData((prevFormData) => ({
          ...prevFormData,
          content_type: input,
        }));
        break;
      default:
        break;
    }
  };


  const handleSubmit = async () => {
    try {
      if (currentUser) {
        const postData = {
          ...formData,
          email: currentUser.email,
        };
        // Make the POST request to the Flask backend
        console.log(postData);
        await axios.post(
          `${backendUrl}/queries/create_business_info_health`,
          postData
        );

        navigate("/scheduler");
      } else {
        console.error("Authentication error: No user found.");
        throw new Error("Authentication error: No user found.");
      }
    } catch (error) {
      console.error("Error processing the form submission:", error);
    }
  };

  useEffect(() => {
    if (conversationEndRef.current) {
      conversationEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation, loading]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [currentQuestionIndex, loading]);

  return (
    <div className="main-container bg-gray-100 min-h-screen flex flex-col items-center justify-center">
      <header className="bg-teal-600 w-full py-4 text-center text-white">
        <h1 className="text-3xl flex justify-center items-center gap-4">
          <img src={logo} alt="Logo" className="w-12" />
          Welcome to Swell
          <img src={logo} alt="Logo" className="w-12" />
        </h1>
      </header>
      <div className="content p-6 bg-white shadow-lg rounded-lg w-full max-w-3xl mt-6">
        <p className="welcome-message text-xl text-gray-700 mb-4 text-center">
          Answer the questions so that Swell can have all of your brand's info to understand you better! 🚀
        </p>
        <div className="questionnaire">
        <div className="mb-4 text-lg font-semibold text-gray-800 text-center">
          Question {Math.min(currentQuestionIndex + 1, questions.length)} of {questions.length}.
        </div>
          <div className="conversation space-y-4">
            {conversation.map((entry, index) => (
              <div key={index} className="message">
                <div className="question-bubble bg-teal-100 p-3 rounded-lg shadow-sm">
                  {entry.question}
                </div>
                <div className="answer-bubble bg-gray-200 p-3 rounded-lg shadow-sm relative">
                  {index === editIndex ? (
                    <input
                      type="text"
                      value={currentInput}
                      onChange={(e) => setCurrentInput(e.target.value)}
                      className="border border-teal-400 rounded p-2 w-full"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleNext();
                        }
                      }}
                    />
                  ) : (
                    <>
                      {entry.answer}
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => handleEdit(index)}
                        className="edit-icon text-gray-500 cursor-pointer absolute right-2 top-1/2 transform -translate-y-1/2"
                      />
                    </>
                  )}
                </div>
              </div>
            ))}
            {currentQuestionIndex < questions.length && (
              <div className="message current">
                {!loading && (
                  <div className="question-bubble bg-teal-100 p-3 rounded-lg shadow-sm">
                    {currentQuestion}
                  </div>
                )}
                {loading ? (
                  <div className="typing-indicator flex justify-center items-center space-x-1 mt-2">
                    <span className="bg-gray-500 w-2 h-2 rounded-full animate-pulse"></span>
                    <span className="bg-gray-500 w-2 h-2 rounded-full animate-pulse delay-75"></span>
                    <span className="bg-gray-500 w-2 h-2 rounded-full animate-pulse delay-150"></span>
                  </div>
                ) : (
                  <input
                    ref={inputRef}
                    type="text"
                    value={editIndex === null ? currentInput : ""}
                    onChange={handleInputChange}
                    className="border border-teal-400 rounded p-2 w-full mt-2"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleNext();
                      }
                    }}
                  />
                )}
              </div>
            )}
            <div ref={conversationEndRef} />
          </div>
          {currentQuestionIndex >= questions.length && (
            <button
              onClick={handleSubmit}
              className="bg-teal-600 text-white font-bold py-2 px-6 rounded-full mt-4 hover:bg-teal-700 transition duration-300 ease-in-out"
            >
              Submit 🚀
            </button>
          )}
        </div>
      </div>
      <footer className="bg-white text-teal-600 text-center py-2 w-full mt-auto">
        <p>Thank you for visiting! 🌟</p>
      </footer>
    </div>
  );
}

export default Home;
