import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import { Stepper, Step, StepLabel } from "@mui/material";
import StepOne from "./step-one";
import StepTwo from "./step-two";

// const backendUrl = "http://localhost:8080"; // Replace with your backend URL
const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";

export default function NewAIContent() {
  const { currentUser } = useAuth();
  const location = useLocation();
  const plannedPost = location.state?.plannedPost || null; // Retrieve entire planned post from state
  console.log(plannedPost);

  const [formData, setFormData] = useState({
    toggleActive: true,
    activeButton: "",
    purpose: "",
    audience: "",
    product: plannedPost?.post_type || "",
    music: "",
    duration: "",
    textAreaInput: plannedPost?.post_description || "",
    productOptions: [],
    clips: [],
    business_name: "",
    categories: "",
    tone_voice: "",
    content_type: "",
    planned_post_id: plannedPost?.planned_post_id || "",
  });

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const steps = ["Describe your reel", "Visualize your script"];

  // Fetch additional user or business data
  useEffect(() => {
    if (currentUser && currentUser.email) {
      const fetchUserData = async () => {
        try {
          const userResponse = await axios.get(`${backendUrl}/queries/data_user`);
          const users = userResponse.data;
          const user = users.find((item) => item.email === currentUser.email);

          if (user) {
            const userId = user.user_id;

            const businessResponse = await axios.get(
              `${backendUrl}/queries/data_business_info_health`
            );
            const businessData = businessResponse.data;

            const userData = businessData.find(
              (item) => item.user_id === userId
            );

            if (userData) {
              const productOptions = userData.categories.split(",").map((cat) => cat.trim());

              setFormData((prev) => ({
                ...prev,
                productOptions, // Categories are the product options
                audience: userData.target_customer || "",
                business_name: userData.brand_name || "",
                categories: userData.categories || "",
                tone_voice: userData.tone_voice || "",
                content_type: userData.content_type || "",
              }));
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, [currentUser]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const generateScript = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${backendUrl}/reel_generation`, formData);
      const receivedData = response.data.choices[0].message.content;

      const cleanedData = receivedData.replace(/```json|```/g, "").trim();
      const clipsArray = JSON.parse(cleanedData).clips;

      setFormData((prev) => ({ ...prev, clips: clipsArray }));
      setActiveStep(1); // Move to StepTwo
    } catch (error) {
      console.error("Error generating script:", error);
    } finally {
      setLoading(false);
    }
  };

  const exportScript = () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `${formData.business_name} Reel Script`,
                  bold: true,
                  size: 36,
                }),
              ],
            }),
            ...formData.clips.map((clip, index) => [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Clip ${index + 1}`,
                    bold: true,
                  }),
                ],
              }),
              new Paragraph({
                children: [new TextRun(`Description: ${clip.description}`)],
              }),
              new Paragraph({
                children: [new TextRun(`Text Overlay: ${clip.textOverlay}`)],
              }),
            ]).flat(),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${formData.business_name}_Script.docx`);
    });
  };

  const handleNextStep = async () => {
    if (activeStep === 0) {
      await generateScript();
    }
  };

  const handlePreviousStep = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepOne formData={formData} handleInputChange={handleInputChange} />
        );
      case 1:
        return <StepTwo formData={formData} />;
      default:
        return null;
    }
  };

  return (
    <div className="font-dm-sans">
      <div className="mt-2">
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      {loading ? <div>Loading...</div> : getStepContent(activeStep)}

      <div className="fixed bottom-5 left-[15%] right-0 flex justify-between px-8 py-4">
        <button
          onClick={handlePreviousStep}
          className={`px-4 py-2 rounded ${
            activeStep === 0
              ? "bg-gray-400 text-gray-700 cursor-not-allowed"
              : "bg-teal text-white hover:bg-light-teal transition"
          }`}
          disabled={activeStep === 0}
        >
          Previous
        </button>
        {activeStep === steps.length - 1 ? (
          <button
            onClick={exportScript}
            className="px-4 py-2 rounded bg-green-600 text-white hover:bg-green-500 transition"
          >
            Export
          </button>
        ) : (
          <button
            onClick={handleNextStep}
            className="px-4 py-2 rounded bg-teal text-white hover:bg-light-teal transition"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}
