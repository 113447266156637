import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Draggable } from "@fullcalendar/interaction";

export default function Step4({ data = { topics: {}, formats: {}, categories: [] }, updateData }) {
  const { topics, categories } = data; // Categories are passed from Step2
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [remainingTopics, setRemainingTopics] = useState(Object.entries(topics));
  const sidebarRef = useRef(null);
  const addedEventIds = useRef(new Set()); // Track added event IDs

  // Generate colors dynamically for each category
  const categoryColors = useRef(generateCategoryColors(categories));
  console.log(categoryColors)
  useEffect(() => {
    if (sidebarRef.current) {
      new Draggable(sidebarRef.current, {
        itemSelector: ".draggable",
        eventData: (el) => {
          const topic = JSON.parse(el.getAttribute("data-topic"));
          return {
            id: topic.id,
            title: topic.topic,
            backgroundColor: topic.color,
            borderColor: topic.color,
            extendedProps: topic,
          };
        },
      });
    }
  }, []);

  // Sync remaining topics with the parent component
  useEffect(() => {
    updateData(
      "remainingTopics",
      remainingTopics.map(([id, topic]) => ({ id, topic }))
    );
  }, [remainingTopics, updateData]);

  // Sync calendar events with the parent component whenever they change
  useEffect(() => {
    updateData("calendarEvents", calendarEvents);
  }, [calendarEvents, updateData]);

  const handleEventReceive = (info) => {
    const topic = info.event.extendedProps;

    if (addedEventIds.current.has(topic.id)) {
      console.warn(`Duplicate event blocked: ${topic.id}`);
      return;
    }

    const newEvent = {
      id: topic.id,
      title: topic.topic,
      start: info.event.start,
      backgroundColor: topic.color,
      borderColor: topic.color,
      type: topic.id.replace(/\s\d+$/, ""),
    };

    setCalendarEvents((prev) => {
      const updatedEvents = [...prev, newEvent];
      addedEventIds.current.add(topic.id); // Track this ID
      return updatedEvents;
    });

    // Remove the topic from the sidebar
    setRemainingTopics((prev) => prev.filter(([id]) => id !== topic.id));
  };

  return (
    <div className="flex flex-col items-start bg-gray-100 pt-4 px-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-4 self-center">Plan Your Week</h1>

      <div className="flex w-full">
        {/* Draggable Sidebar */}
        <div
          ref={sidebarRef}
          className="w-1/5 bg-white shadow-lg rounded-lg p-2 overflow-y-auto max-h-[80vh]"
        >
          <h2 className="text-lg font-bold mb-2">Topics</h2>
          {remainingTopics.map(([id, topic]) => {
            const category = id.replace(/\s\d+$/, "").toLowerCase(); // Removes the space and number at the end
            const color = categoryColors.current[category] || "#d3d3d3"; // Default to gray if no color found
            return (
              <div
                key={id}
                className="p-1 mb-1 rounded-lg text-xs shadow-md cursor-move whitespace-nowrap overflow-hidden text-ellipsis draggable"
                data-topic={JSON.stringify({ id, topic, category, color })}
                draggable="true"
                style={{
                  backgroundColor: color,
                  color: "#000",
                  maxWidth: "150px",
                }}
              >
                {topic} ({category})
              </div>
            );
          })}
        </div>

        {/* Calendar */}
        <div className="w-4/5 bg-white shadow-lg rounded-lg p-4 ml-4">
          <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            editable={true}
            droppable={true}
            events={calendarEvents}
            eventReceive={handleEventReceive}
            slotMinTime="08:00:00"
            slotMaxTime="24:00:00"
            slotDuration="01:00:00"
            height="auto"
            allDaySlot={false}
            slotLabelFormat={{
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            }}
          />
        </div>
      </div>
    </div>
  );
}

// Helper function to dynamically generate colors for categories
function generateCategoryColors(categories) {
  const colors = [
    "#add8e6", // Light blue
    "#90ee90", // Light green
    "#f08080", // Light red
    "#ffffe0", // Light yellow
    "#ffd700", // Gold
    "#ffb6c1", // Light pink
    "#dda0dd", // Plum
  ];

  const categoryColorMap = {};
  categories.forEach((category, index) => {
    categoryColorMap[category] = colors[index % colors.length]; // Cycle through colors
  });

  return categoryColorMap;
}
