import React, { useState, useEffect } from "react";

export default function Step3({ data = { formats: {}, topics: {} }, updateData, onTopicsCompleteChange }) {
  const { formats } = data;
  const [topics, setTopics] = useState(data.topics || {});

  // Utility to capitalize the first letter
  const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1).replace(/s$/, ""); // Remove plural 's'

  // Generate a list of post identifiers dynamically
  const postList = Object.entries(formats).flatMap(([type, count]) =>
    Array.from({ length: count }, (_, index) => `${capitalize(type)} ${index + 1}`)
  );

  useEffect(() => {
    const allTopicsFilled = postList.every((postId) => topics[postId] && topics[postId].trim() !== "");
    onTopicsCompleteChange(allTopicsFilled); // Notify parent component about the completion status
  }, [topics, postList, onTopicsCompleteChange]);

  const handleTopicChange = (postId, value) => {
    const updatedTopics = { ...topics, [postId]: value };
    setTopics(updatedTopics);
    updateData("topics", updatedTopics);
  };

  return (
    <div className="flex flex-col items-center justify-start bg-gray-100 pt-12 px-4">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">Plan Your Topics</h1>
      <p className="text-lg mb-8 text-gray-700">
        Assign topics to each post you’ve planned below.
      </p>
      <div className="w-full max-w-4xl space-y-6">
        {postList.map((postId, index) => (
          <div
            key={index}
            className="flex items-center space-x-4 bg-white shadow-lg rounded-lg p-4"
          >
            <label
              htmlFor={`topic-${index}`}
              className="text-lg font-bold text-gray-800 flex-shrink-0 w-28"
            >
              {postId}:
            </label>
            <input
              id={`topic-${index}`}
              type="text"
              value={topics[postId] || ""}
              onChange={(e) => handleTopicChange(postId, e.target.value)}
              placeholder={`Write a topic for ${postId}`}
              className="flex-1 bg-gray-100 p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
