import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  Divider,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom"; // React Router for navigation
import logo from "../../assets/new-logo.svg";
import defaultImage from "../../assets/placeholder_image.png";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
// const backendUrl = 'http://localhost:8080'

const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";

export default function Topbar() {
  const { currentUser } = useAuth();
  const [userName, setUserName] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser && currentUser.email) {
        try {
          const userResponse = await axios.get(
            `${backendUrl}/queries/data_user`,
          );
          const users = userResponse.data;
          const user = users.find((item) => item.email === currentUser.email);

          if (user) {
            setUserName(user.name);
            const userId = user.user_id;
            fetchProfilePicture(userId);
          } else {
            console.error("User not found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const fetchProfilePicture = async (userId) => {
      try {
        const response = await axios.get(
          `${backendUrl}/api/profile_picture/${userId}`,
        );
        setProfilePicture(response.data.profile_picture_url);
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the dropdown
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the dropdown
  };

  const handleLogout = () => {
    setAnchorEl(null);
    navigate("/login"); // Redirect to login
  };

  const handleAccount = () => {
    setAnchorEl(null);
    navigate("/account"); // Redirect to account
  };

  return (
    <div
      className="bg-[#f7f6f5] flex justify-between items-center w-full px-10 py-4 shadow-sm fixed"
      style={{ backgroundColor: "#f7f6f2" }}
    >
      <div className="flex items-center justify-start w-1/2">
        <img src={logo} alt="Logo" className="w-10 h-10" />
        <h1 className="text-grey text-3xl md:text-4xl font-dm-serif ml-4">
          Swell<span className="text-teal">Al</span>
        </h1>
      </div>

      <div className="flex items-center justify-end w-1/2">
        {/* Interactive Profile Picture */}
        <motion.div
          whileHover={{ scale: 1.1 }} // Enlarge on hover
          whileTap={{ scale: 0.95 }} // Slight shrink on click
          className="cursor-pointer"
          onClick={handleProfileClick}
        >
          <img
            src={profilePicture || defaultImage}
            alt="User"
            className="w-10 h-10 rounded-full"
          />
        </motion.div>

        <div className="flex flex-col ml-2 whitespace-nowrap">
          <p className="text-md">{userName}</p>
          <p className="text-sm text-gray-500">Admin</p>
        </div>
      </div>

      {/* Professional Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 4,
          sx: {
            width: 230,
            padding: "8px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <MenuItem onClick={handleAccount}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Your Account</Typography>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Settings</Typography>
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Logout</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
