import React, { useState, useEffect } from "react";
import axios from "axios"; // You'll need this to make API calls
import LeftChevron from "../../assets/left-chevron.png";
import RightChevron from "../../assets/right-chevron.png";
import { FaStar } from 'react-icons/fa'; // Import Font Awesome star icon
import { useAuth } from "../../contexts/AuthContext"; // Use the same AuthContext to get the user

export default function StepTwo({ formData, reelId }) {
  const { currentUser } = useAuth(); // Get current user from the Auth context
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const clipsPerPage = 3;
  const totalDots = Math.ceil(formData.clips.length / clipsPerPage);
  const [userId, setUserId] = useState(null); // To store user_id once fetched
  // const backendUrl = "http://127.0.0.1:8080";
  const backendUrl = 'https://socialoha-server-0b454a3e2f86.herokuapp.com';
  // Check if the reel is saved when the component loads
  
  useEffect(() => {
    const fetchUserId = async () => {
      if (currentUser && currentUser.email) {
        try {
          const userResponse = await axios.get(`${backendUrl}/queries/data_user`);
          const users = userResponse.data;
          const user = users.find((item) => item.email === currentUser.email);

          if (user) {
            setUserId(user.user_id); // Set the user ID once found
          } else {
            console.log("No user found with the provided email.");
          }
        } catch (error) {
          console.error("Error fetching user ID:", error);
        }
      }
    };

    fetchUserId();
  }, [currentUser]);
  useEffect(() => {
    if (userId) {
      const fetchFavoriteStatus = async () => {
        try {
          const response = await axios.get(`${backendUrl}/queries/saved_reels/${userId}`);
          const savedReels = response.data;
          const isSaved = savedReels.some(reel => reel.saved_reel_id === reelId);
          setIsFavorite(isSaved); // Set the initial favorite status
        } catch (error) {
          console.error("Error checking favorite status:", error);
        }
      };

      fetchFavoriteStatus();
    }
  }, [reelId, userId]);
  const nextSlide = () => {
    if (currentIndex < totalDots - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Handle favorite button toggle
  const toggleFavorite = async () => {
    if (isFavorite) {
      // Unsave the reel
      try {
        await axios.delete(`${backendUrl}/queries/delete_reel/${reelId}`);
        setIsFavorite(false); // Update the state to unsaved
      } catch (error) {
        console.error("Error unsaving the reel:", error);
      }
    } else {
      // Save the reel, including the clips
      try {
        const lastClip = formData.clips[formData.clips.length - 1]; 
        const reel_duration = lastClip.startTime.split('-')[1].split(' ')[0];;
        await axios.post(
          `${backendUrl}/queries/create_saved_reel/${userId}`,
          {
            title: formData.mainIdea,
            summary: formData.subIdea,
            duration: reel_duration, // Extract the numeric part of duration
            planned_post_id: formData.planned_post_id || null, // Include planned_post_id if it exists
            clips: formData.clips.map(clip => ({
              description: clip.description,
              start_time: clip.startTime.split(" ")[0], // Extract start time in seconds
              end_time: clip.startTime.split(" ")[1],   // If needed, include end time if available
              text_overlay: clip.textOverlay
            }))
          }
        );
        setIsFavorite(true); // Update the state to saved
      } catch (error) {
        console.error("Error saving the reel:", error);
      }
    }
  };
  

  return (
    <div className="px-16 mt-16 relative">
      {/* Favorite Button at the top-right, aligned with "Visualize your reel" */}
      <div className="absolute top-[-130px] right-20 mt-4"> {/* Adjust the margin to move it higher */}
        <button
          className={`text-white font-bold ${
            isFavorite ? "bg-yellow-400" : "bg-gray-300"
          } rounded-full p-2`}
          onClick={toggleFavorite}
        >
          <FaStar size={24} />
        </button>
      </div>

      <div className="flex flex-col items-center">
        <div className="flex items-center">
          <button
            className={`absolute left-4 ${
              currentIndex === 0 ? "opacity-50 cursor-not-allowed" : "opacity-100 cursor-pointer"
            }`}
            onClick={prevSlide}
            disabled={currentIndex === 0}
          >
            <img src={LeftChevron} alt="Left Chevron" className="w-8 h-8" />
          </button>

          <div className="flex flex-row space-x-8 overflow-hidden w-full">
            {formData.clips
              .slice(currentIndex * clipsPerPage, currentIndex * clipsPerPage + clipsPerPage)
              .map((clip, index) => (
                <div
                  key={index}
                  className="flex flex-col space-y-4 p-4 border border-gray-300 rounded shadow-md w-full"
                >
                  <div className="h-10 flex flex-row justify-between items-center">
                    <p className="font-bold">Clip {currentIndex * clipsPerPage + index + 1}</p>
                    <p className="text-sm text-gray-500">{clip.startTime} - {clip.endTime} seconds</p>
                  </div>
                  <div className="h-56">
                    <label className="font-semibold">Script</label>
                    <p className="mt-2 text-gray-500 text-md">{clip.description}</p>
                  </div>
                  <div className="">
                    <label className="font-semibold">Text Overlay</label>
                    <p className="mt-1 text-gray-500">{clip.textOverlay}</p>
                  </div>
                </div>
              ))}
          </div>

          <button
            className={`absolute right-4 ${
              currentIndex >= formData.clips.length - clipsPerPage
                ? "opacity-50 cursor-not-allowed" : "opacity-100 cursor-pointer"
            }`}
            onClick={nextSlide}
            disabled={currentIndex >= formData.clips.length - clipsPerPage}
          >
            <img src={RightChevron} alt="Right Chevron" className="w-8 h-8" />
          </button>
        </div>

        <div className="flex mt-6 space-x-2">
          {Array.from({ length: totalDots }).map((_, dotIndex) => (
            <div
              key={dotIndex}
              className={`w-3 h-3 rounded-full ${
                currentIndex === dotIndex ? "bg-teal" : "bg-gray-300"
              }`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}
