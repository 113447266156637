import React, { useState, useCallback } from "react";
import { Stepper, Step, StepLabel } from "@mui/material";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";

export default function PlannerStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    frequency: 0,
    formats: {}, // Will be dynamically populated by categories
    topics: {}, // Topics object
    calendarEvents: [], // Assigned events,
    remainingTopics: [], // Remaining unassigned topics
  });
  const { currentUser } = useAuth();
  // const backendUrl = 'http://localhost:8080';
  const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";
  const steps = ["Frequency", "Categories", "Topics", "Days"];
  const navigate = useNavigate(); // Initialize navigate
  const [totalCount, setTotalCount] = useState(0); // State to track total count of selected posts
  const [allTopicsFilled, setAllTopicsFilled] = useState(false); // State to track if all topics are filled

  const handleNext = async () => {
    if (activeStep === 3) { // Step 4: Create planned posts
      if (!currentUser || !currentUser.email) {
        alert("User is not authenticated.");
        return;
      }

      try {
        // Step 1: Fetch user data and find user_id
        const userResponse = await axios.get(`${backendUrl}/queries/data_user`);
        const users = userResponse.data;
        const user = users.find((item) => item.email === currentUser.email);

        const userId = user.user_id;
        for (const event of formData.calendarEvents) {
          const plannedPost = {
            user_id: userId, // Use user_id from the fetched user
            post_type: event.type || "N/A",
            post_description: event.title,
            scheduled_time: event.start,
            planned_time: new Date().toISOString(), // Current time
          };

          // Step 3: Send each planned post to the backend
          try {
            await axios.post(`${backendUrl}/queries/create_planned_posts/${userId}`, {
              plannedPosts: [plannedPost],
            });
          } catch (err) {
            console.error(`Error creating planned post for event: ${event.title}`, err);
            alert(`Failed to create planned post for "${event.title}".`);
          }
        }

        navigate("/scheduler/calendar"); // Redirect to calendar page
      } catch (err) {
        console.error("Error during user lookup or post creation:", err);
        alert("An error occurred while creating planned posts.");
      }

      return;
    }

    setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const handleBack = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const updateFormData = useCallback((key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: key === "formats" ? { ...prev.formats, ...value } : value, // Merge formats dynamically
    }));
  }, []);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step1 updateFormData={updateFormData} nextStep={handleNext} />;
      case 1:
        return (
          <Step2
            data={formData}
            updateData={updateFormData}
            onTotalCountChange={setTotalCount}
          />
        );
      case 2:
        return (
          <Step3
            data={formData}
            updateData={updateFormData}
            onTopicsCompleteChange={setAllTopicsFilled}
          />
        );
      case 3:
        return <Step4 data={formData} updateData={updateFormData} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Stepper Header */}
      <div className="p-6">
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      {/* Step Content */}
      <div className="flex-1">{getStepContent(activeStep)}</div>

      {/* Navigation Buttons */}
      <div className="flex justify-between items-center w-full p-6 fixed bottom-0 left-0 right-0 bg-gray-100">
        <button
          onClick={handleBack}
          className={`ml-80 px-6 py-3 rounded-md ${
            activeStep === 0
              ? "bg-gray-400 text-gray-700 cursor-not-allowed"
              : "bg-teal-500 text-white hover:bg-teal-600"
          }`}
          disabled={activeStep === 0}
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          className={`px-6 py-3 rounded-md ${
            totalCount < formData.frequency || (activeStep === 2 && !allTopicsFilled)
              ? "bg-gray-400 text-gray-700 cursor-not-allowed"
              : "bg-teal-500 text-white hover:bg-teal-600"
          }`}
          disabled={totalCount < formData.frequency || (activeStep === 2 && !allTopicsFilled)}
        >
          Next
        </button>
      </div>
    </div>
  );
}
