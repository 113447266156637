import React, { useState } from "react";

export default function Step1({ nextStep, updateFormData }) {
  const options = [1, 3, 5, 7];
  const [selected, setSelected] = useState(null);
  const [customFrequency, setCustomFrequency] = useState("");
  const [isFocused, setIsFocused] = useState(false); // Track focus state

  const handleSelect = (option) => {
    setSelected(option);
    updateFormData("frequency", option);
    setTimeout(nextStep, 300); // Move to next step after selection
  };

  const handleCustomInput = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // Allow only numeric input
      setCustomFrequency(value);
      updateFormData("frequency", parseInt(value, 10) || 0); // Update parent state
    }
  };

  const handleFocus = () => setIsFocused(true); // Set focus state to true
  const handleBlur = () => setIsFocused(false); // Set focus state to false

  return (
    <div className="flex flex-col items-center justify-start h-screen bg-gray-100 pt-20">
      {/* Title */}
      <h1 className="text-4xl font-bold text-gray-800 mb-8">
        With what frequency do you want to post?
      </h1>

      {/* Options */}
      <div className="flex space-x-4 mb-8">
        {options.map((option) => (
          <button
            key={option}
            onClick={() => handleSelect(option)}
            className={`w-32 h-32 flex items-center justify-center rounded-lg shadow-md text-lg font-semibold ${
              selected === option
                ? "bg-teal-500 text-white scale-105"
                : "bg-white text-gray-800 hover:bg-teal-100"
            } transition-transform duration-300`}
          >
            {option}x / week
          </button>
        ))}
      </div>

      {/* Custom Frequency Editable Box */}
      <div
        className={`w-32 h-32 flex items-center justify-center rounded-lg shadow-md text-lg font-semibold bg-white border-2 ${
          isFocused || customFrequency
            ? "border-[#21C0A8]" // Custom teal border color
            : "border-gray-200"
        } transition-colors duration-300`}
      >
        <input
          type="text"
          value={customFrequency}
          onChange={handleCustomInput}
          onFocus={handleFocus} // Handle focus
          onBlur={handleBlur} // Handle blur
          placeholder=""
          className="absolute inset-0 w-full h-full text-center bg-transparent border-none outline-none text-gray-800 font-bold"
          style={{
            opacity: 0, // Makes input invisible
            cursor: "text", // Keeps it editable
          }}
        />
        <div className="pointer-events-none text-gray-800">
          {customFrequency ? `${customFrequency}x / week` : "? x / week"}
        </div>
      </div>
    </div>
  );
}
