import React from "react";

export default function StepOne({ formData, handleInputChange }) {
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update formData state
    handleInputChange(e);

    // Set mainIdea and subIdea based on the new values
    const purpose = formData.purpose || value;
    const audience = formData.audience || value;
    formData.mainIdea = `Reel to ${purpose} for ${audience}`;

    if (name === "textAreaInput") {
      formData.subIdea = value;
    }
  };

  return (
    <div>
      <div className="flex flex-row space-x-8 px-8 mt-16">
        <div className="flex-1">
          <p className="font-bold">Purpose</p>
          <input
            name="purpose"
            value={formData.purpose}
            onChange={handleChange} // Use the new handleChange function
            placeholder="Enter"
            className="w-full mt-2 px-4 py-3 outline outline-1 outline-light-teal rounded shadow-bottom-gray"
          />
        </div>

        <div className="flex-1">
          <p className="font-bold">Audience</p>
          <input
            name="audience"
            value={formData.audience}
            onChange={handleChange} // Use the new handleChange function
            placeholder={formData.audience || "Enter audience"}
            className="w-full mt-2 px-4 py-3 outline outline-1 outline-light-teal rounded shadow-bottom-gray"
          />
        </div>

        <div className="flex-1">
          <p className="font-bold">Product</p>
          <div className="relative mt-2">
            <select
              name="product"
              value={formData.product}
              onChange={handleChange}
              className="w-full px-4 py-3 outline outline-1 outline-light-teal rounded h-[50px] appearance-none shadow-bottom-gray"
              style={{
                color: formData.product === "" ? "gray" : "black",
                backgroundImage:
                  "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNC4yOTMgNi4yOTNsNC4yOTMgNC4yOTMgNC4yOTMtNC4yOTMgMS40MTQgMS40MTRsLTUuNzA3IDUuNzA3LTUuNzA3LTUuNzA3eiIgZmlsbD0iI2M4YzljOSIgc3R5bGU9ImZpbGw6I2M4YzljOSIvPgo8L3N2Zz4=)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "calc(100% - 12px) center",
              }}
            >
              <option value="">Select</option>
              {formData.productOptions?.map((product, index) => (
                <option key={index} value={product.trim()}>
                  {product.trim()}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex-1">
          <p className="font-bold">Duration</p>
          <div className="relative mt-2">
            <select
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              className="w-full px-4 py-3 outline outline-1 outline-light-teal rounded h-[50px] appearance-none shadow-bottom-gray"
              style={{
                color: formData.duration === "" ? "gray" : "black",
                backgroundImage:
                  "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNC4yOTMgNi4yOTNsNC4yOTMgNC4yOTMgNC4yOTMtNC4yOTMgMS40MTQgMS40MTRsLTUuNzA3IDUuNzA3LTUuNzA3LTUuNzA3eiIgZmlsbD0iI2M4YzljOSIgc3R5bGU9ImZpbGw6I2M4YzljOSIvPgo8L3N2Zz4=)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "calc(100% - 12px) center",
              }}
            >
              <option value="">Select</option>
              <option value="2-15">2-15 seconds</option>
              <option value="15-30">15-30 seconds</option>
              <option value="30-60">30-60 seconds</option>
              <option value="60-90">60-90 seconds</option>
            </select>
          </div>
        </div>
      </div>

      <div className="mt-8 px-8">
        <p className="font-bold">Content</p>
        <textarea
          name="textAreaInput"
          value={formData.textAreaInput}
          onChange={handleChange} // Use the new handleChange function
          placeholder="Describe your content for this reel"
          className="w-full mt-2 h-80 px-2 py-2 shadow-lg outline outline-1 outline-light-teal rounded"
        />
      </div>
    </div>
  );
}
