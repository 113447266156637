import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/home";
import SignUp from "./components/SignUp/signup";
import NewSignup from "./components/SignUp/new-signup";
import Login from "./components/Login/login";
import StepperIdeate from "./components/AIContent/stepper_ideate";
import AIContent from "./components/AIContent/aicontent";
import Dashboard from "./components/Dashboard/dashboard";
import NewLandingPage from "./components/LandingPage/newlandingpage";
import PrivacyPolicy from "./components/PrivacyPolicy/privacypolicy";
import TermsOfService from "./components/TermsOfService/termsofservice";
import FeedbackSurvey from "./components/FeedbackSurvey/feedbacksurvey";
import Contact from "./components/Contact/contact";
import Scheduler from "./components/Scheduler/scheduler";
import LandingPage from "./components/LandingPage/landingpage";
import NewAIContent from "./components/AIContent/new-aicontent";
import Navbar from "./components/Navbar/navbar";
import Topbar from "./components/Topbar/topbar";
import Analytics from "./components/Analytics/analytics";
import Account from "./components/Account/account";
import SavedReels from "./components/AIContent/saved_reels";
import Planner from "./components/Planner/plannerstepper";
import ToDo from "./components/ToDo/todo";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/SignUp/*" element={<NewSignup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<NewLandingPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/feedback-survey" element={<FeedbackSurvey />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="*"
          element={
            <div className="flex flex-col h-screen">
              <div className="fixed top-0 left-0 right-0 z-50 h-[10vh]">
                <Topbar />
              </div>
              <div className="flex pt-[10vh] h-[90vh]">
                <div className="w-[12%] h-full">
                  <Navbar />
                </div>
                <div className="w-[88%] h-full">
                  <Routes>
                    <Route path="/ideate" element={<StepperIdeate />} />
                    <Route path="/aicontent" element={<NewAIContent />} />
                    <Route path="/analytics" element={<Dashboard />} />
                    <Route path="/scheduler/*" element={<Scheduler />} />
                    <Route path="/dashboard/*" element={<Analytics />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/saved_reels" element={<SavedReels />} />
                    <Route path="/planner" element={<Planner />} />
                    <Route path="/todo" element={<ToDo />} />
                  </Routes>
                </div>
              </div>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
