import React from "react";
import "./contact.scss";
import logo from "../../assets/socialoha_logo.png";

const Contact = () => {
  return (
    <div className="contact-us-container">
      <header>
        <nav>
          <div className="logo">
            <img src={logo} alt="Logo" className="logo" />
            <h1>SociAloha</h1>
          </div>
          <ul className="nav-links">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/feedback-survey">Survey</a>
            </li>
            <li>
              <a href="/terms-of-service">Terms of Service</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            <li>
              <a href="/SignUp">Try it Out</a>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <section id="contact">
          <h2>Contact Us</h2>
          <form action="/submit-contact" method="post">
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" required />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" required />
            </div>

            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                required
              ></textarea>
            </div>

            <button type="submit">Submit</button>
          </form>
          <div className="contact-info">
            <p>For immediate assistance, please contact us at:</p>
            <p>
              Email:{" "}
              <a href="mailto:Swellmail@gmail.com">
                Swellmail@gmail.com
              </a>
            </p>
            <p>Business Hours: feel free to contact us at any time!</p>
            <p>
              Instagram:{" "}
              <a href="https://www.instagram.com/socialoha_">socialoha_</a>
            </p>
            <p>
              Facebook:{" "}
              <a href="https://www.facebook.com/profile.php?id=61560513243032&locale=et_EE">
                Swell
              </a>
            </p>
            <p>
              LinkedIn:{" "}
              <a href="https://www.linkedin.com/company/socialohaa">
                Swell
              </a>
            </p>
          </div>
        </section>
        <section id="faq">
          <h2>Frequently Asked Questions (FAQs)</h2>
          <div className="faq-item">
            <h3>What is SociAloha?</h3>
            <p>
              Swell is a social media management platform designed
              specifically for solo entrepreneurs and small businesses. We help
              automate your social media scheduling and provide simple analytics
              to enhance your online presence.
            </p>
          </div>
          <div className="faq-item">
            <h3>Who is SociAloha for?</h3>
            <p>
              Swell is ideal for small business owners, solo entrepreneurs,
              and creative professionals such as designers and handcrafters who
              want to streamline their social media efforts without compromising
              on quality or authenticity.
            </p>
          </div>
          <div className="faq-item">
            <h3>What features does SociAloha currently offer?</h3>
            <p>
              <strong>Scheduler:</strong> Automatically schedule your social
              media posts at optimal times for engagement.
            </p>
            <p>
              <strong>Analytics:</strong> Receive simple, actionable insights to
              improve your social media strategy.
            </p>
          </div>
          <div className="faq-item">
            <h3>What features are coming soon?</h3>
            <p>
              We are excited to roll out additional features in the coming
              months, including:
            </p>
            <ul>
              <li>
                <strong>Content Creation:</strong> Generate personalized
                content, including TikTok/reel scripts and post captions.
              </li>
              <li>
                <strong>Brand Authenticity:</strong> Tools to maintain your
                unique brand voice across all content.
              </li>
              <li>
                <strong>Advanced Analytics:</strong> More detailed insights and
                reporting features.
              </li>
            </ul>
          </div>
          <div className="faq-item">
            <h3>How does SociAloha help save time?</h3>
            <p>
              Swell automates the tedious task of scheduling posts, allowing
              you to focus on other important aspects of your business. With our
              platform, you can significantly reduce the time spent on social
              media management.
            </p>
          </div>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 SociAloha. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Contact;
