import React, { useState } from "react";
import { loginUser, loginGoogleUser } from "../../services/firebaseService";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "../../assets/google-icon.svg";
import logo from "../../assets/new-logo.svg";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await loginUser(email, password);
      console.log(`Logged in as: ${user.email}`);
      navigate("/scheduler");
    } catch (error) {
      alert("Failed to log in: " + error.message);
    }
  };

  const handleGoogleSubmit = async () => {
    try {
      await loginGoogleUser();
      navigate("/scheduler");
    } catch (error) {
      alert("Failed to log in: " + error.message);
    }
  };

  return (
    <div className="bg-cream h-screen flex flex-col items-center justify-center">
      <a
        href="/signup"
        className="absolute right-8 top-8 hover:bg-accent hover:text-accent-foreground"
      >
        Sign Up
      </a>

      <div className="absolute left-5 top-6 flex flex-row">
      <img src={logo} alt="Logo" className="w-10 h-10" />
        <h1 className="text-grey text-3xl md:text-4xl font-dm-serif ml-4">
          Swell<span className="text-teal">Al</span>
        </h1>
      </div>

      <div className="flex flex-col w-[360px] p-6 border border-gray-300 rounded-lg shadow-lg bg-white">
        <div className="space-y-2 mb-8">
          <h2 className="text-3xl font-semibold font-eb-garamond">Login</h2>
          <p className="text-sm text-muted-foreground text-grey-300 font-dm-sans">
            Enter your email and password below to login
          </p>
        </div>

        <input
          type="email"
          placeholder="name@example.com"
          className="p-2 border border-gray-300 rounded-lg placeholder:text-gray-500"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <input
          type="password"
          placeholder="Password"
          className="mt-1 p-2 border border-gray-300 rounded-lg placeholder:text-gray-500"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <div className="mt-6 relative">
          <div className="absolute inset-0 flex items-center">
            <span className="w-full border-t" />
          </div>
          <div className="relative flex justify-center text-xs uppercase">
            <span className="bg-white px-2 text-muted-foreground font-bricolage-grotesque">
              Or continue with
            </span>
          </div>
        </div>

        <div className="mt-4 flex justify-center font-dm-sans">
          <button
            onClick={handleGoogleSubmit}
            className="flex items-center border border-gray-300 text-black py-2 px-4 rounded-lg bg-white hover:bg-gray-300 hover:text-black transition-colors duration-300"
          >
            <img className="mr-2 h-4 w-4" src={GoogleIcon} alt="Google" />
            <p>Google</p>
          </button>
        </div>

        <button
          className="mt-4 p-2 bg-teal text-white rounded-lg hover:bg-light-teal transition-colors duration-500 ease-in-out font-dm-sans"
          onClick={handleSubmit}
        >
          Login
        </button>

        <div className="mt-8 text-center">
          <p className="text-sm text-muted-foreground font-dm-sans">
            Don't have an account yet?{" "}
            <a
              href="/signup"
              className="text-teal text-accent hover:text-accent-foreground"
            >
              Sign Up
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
