import React from "react";
import "./privacypolicy.scss";
import logo from "../../assets/socialoha_logo.png";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <header>
        <nav>
          <div className="logo">
            <img src={logo} alt="Logo" className="logo" />
            <h1>SociAloha</h1>
          </div>
          <ul className="nav-links">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/feedback-survey">Survey</a>
            </li>
            <li>
              <a href="/terms-of-service">Terms of Service</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            <li>
              <a href="/SignUp">Try It Out</a>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <section id="privacy-policy">
          <h2>Privacy Policy</h2>
          <p>Effective Date: June 4, 2024</p>
          <p>Contact Information: socialohamail@gmail.com, 236-869-2318</p>
          <h3>Introduction</h3>
          <p>
            Welcome to Swell. We are committed to protecting your privacy
            and ensuring you have a positive experience using our social media
            management services. This privacy policy explains how we collect,
            use, store, and share your personal information.
          </p>
          <h3>1. Information We Collect</h3>
          <h4>1.1 Personal Information</h4>
          <p>
            When you register on our platform, we collect personal information
            such as:
          </p>
          <ul>
            <li>
              <strong>Name:</strong> To personalize your experience and
              communicate with you.
            </li>
            <li>
              <strong>Email Address:</strong> For account creation,
              notifications, and communication.
            </li>
            <li>
              <strong>Payment Information:</strong> Including credit card
              details for subscription and billing purposes.
            </li>
            <li>
              <strong>Contact Information:</strong> Phone number or address for
              communication and support purposes.
            </li>
          </ul>
          <h4>1.2 Social Media Information</h4>
          <p>
            By using Swell, you grant us access to your social media
            accounts to manage and post content. This includes:
          </p>
          <ul>
            <li>
              <strong>Profile Information:</strong> Username, profile picture,
              bio, and other publicly available profile information.
            </li>
            <li>
              <strong>Content:</strong> Posts, messages, comments, images,
              videos, and other content you upload or interact with on your
              social media accounts.
            </li>
            <li>
              <strong>Engagement Data:</strong> Likes, shares, comments, and
              other interactions with your posts.
            </li>
            <li>
              <strong>Followers Data:</strong> Information about your followers,
              such as names, profile pictures, and engagement data.
            </li>
          </ul>
          <h4>1.3 Permissions and Features</h4>
          <p>We collect specific data through the following API permissions:</p>
          <ul>
            <li>
              instagram_manage_upcoming_events: Read, create, and update
              upcoming events on Instagram.
            </li>
            <li>
              instagram_branded_content_ads_brand: Read Instagram posts tagged
              as paid partnerships.
            </li>
            <li>
              instagram_branded_content_brand: Manage a brand’s approved
              creators list.
            </li>
            <li>
              instagram_branded_content_creator: Change the boost status of a
              creator’s content.
            </li>
            <li>email: Read a person's primary email address.</li>
            <li>
              Business Asset User Profile Access: Read user fields such as id,
              name, and picture.
            </li>
            <li>
              pages_manage_metadata: Subscribe to and receive webhooks about
              Page activity.
            </li>
            <li>
              commerce_account_read_settings: Read commerce account settings.
            </li>
            <li>
              public_profile: Read default public profile fields on the User
              node.
            </li>
            <li>pages_user_locale: Access a user's locale.</li>
            <li>pages_user_timezone: Access a user's time zone.</li>
            <li>
              Live Video API: Manage live videos on Pages, Groups, and User
              timelines.
            </li>
            <li>
              commerce_account_manage_orders: Manage commerce account orders.
            </li>
            <li>
              instagram_shopping_tag_products: Tag Instagram media with product
              tags.
            </li>
            <li>user_messenger_contact: Contact a person via Messenger.</li>
            <li>instagram_manage_comments: Manage comments on Instagram.</li>
            <li>pages_messaging: Manage Page conversations in Messenger.</li>
            <li>
              instagram_manage_messages: Manage Instagram Direct messages.
            </li>
            <li>commerce_account_read_orders: Read commerce account orders.</li>
            <li>
              Instagram Public Content Access: Access Instagram Graph API’s
              Hashtag Search endpoints.
            </li>
            <li>Ads Management Standard Access: Access the Marketing API.</li>
            <li>
              pages_read_engagement: Read content posted by the Page and
              followers data.
            </li>
            <li>
              pages_manage_cta: Manage call-to-action buttons on a Facebook
              Page.
            </li>
            <li>
              instagram_manage_insights: Access insights for Instagram accounts
              linked to a Facebook Page.
            </li>
            <li>
              pages_manage_instant_articles: Manage Instant Articles on Facebook
              Pages.
            </li>
            <li>
              instagram_content_publish: Create organic feed photo and video
              posts on behalf of a business user.
            </li>
            <li>pages_manage_posts: Manage Page posts.</li>
            <li>
              pages_manage_engagement: Manage comments posted on the Page.
            </li>
            <li>
              leads_retrieval: Retrieve information captured by lead ads forms.
            </li>
            <li>page_events: Log events on behalf of Facebook Pages.</li>
            <li>pages_show_list: Access the list of Pages a person manages.</li>
            <li>
              publish_video: Publish live videos to an app user's timeline,
              group, event, or Page.
            </li>
            <li>
              instagram_basic: Read Instagram account profile info and media.
            </li>
            <li>business_management: Manage Business Manager API.</li>
            <li>
              attribution_read: Access Attribution API to pull report data.
            </li>
            <li>
              ads_read: Access Ads Insights API to pull Ads report information.
            </li>
            <li>ads_management: Manage Ads accounts.</li>
          </ul>
          <h4>1.4 Usage Data</h4>
          <p>
            We collect information on how you interact with our services,
            including:
          </p>
          <ul>
            <li>
              <strong>IP Address:</strong> To help diagnose problems with our
              server and to administer our website.
            </li>
            <li>
              <strong>Browser Type:</strong> To understand user preferences and
              provide a better experience.
            </li>
            <li>
              <strong>Usage Patterns:</strong> Interaction with our website,
              pages visited, and time spent on each page.
            </li>
            <li>
              <strong>Device Information:</strong> Type of device used,
              operating system, and mobile network information.
            </li>
          </ul>
          <h4>1.5 Communication Data</h4>
          <p>Information from your communications with us, including:</p>
          <ul>
            <li>
              <strong>Customer Support:</strong> Records of support queries and
              responses.
            </li>
            <li>
              <strong>Surveys and Feedback:</strong> Information you provide in
              response to surveys or feedback requests.
            </li>
          </ul>
          <h3>2. How We Use Your Information</h3>
          <h4>2.1 Providing Services</h4>
          <p>
            We use your information to deliver and manage our social media
            management services, including creating and scheduling posts, and
            providing analytics on your social media performance.
          </p>
          <h4>2.2 Communication</h4>
          <p>
            We use your contact information to send you updates, respond to your
            inquiries, and communicate about your account.
          </p>
          <h4>2.3 Improvement and Analytics</h4>
          <p>
            We analyze usage data to understand how our services are used and to
            make improvements. This includes aggregating data for research and
            reporting purposes.
          </p>
          <h4>2.4 Marketing</h4>
          <p>
            With your consent, we may use your contact information to send you
            marketing communications about our services, special offers, and
            events. You can opt-out of these communications at any time.
          </p>
          <h3>3. Sharing Your Information</h3>
          <h4>3.1 Third-Party Services</h4>
          <p>
            We share your information with third-party service providers who
            assist us in delivering our services, such as hosting providers,
            payment processors, and social media platforms. These providers are
            contractually obligated to protect your information and use it only
            for the purposes we specify.
          </p>
          <h4>3.2 Legal Requirements</h4>
          <p>
            We may disclose your information if required by law or in response
            to valid requests by public authorities, such as a court or
            government agency.
          </p>
          <h4>3.3 Business Transfers</h4>
          <p>
            In the event of a merger, acquisition, or sale of all or a portion
            of our assets, your information may be transferred as part of the
            transaction.
          </p>
          <h3>4. Your Rights</h3>
          <h4>4.1 Access and Control</h4>
          <p>
            You can access, update, or delete your personal information by
            logging into your account. If you need assistance, please contact
            our support team.
          </p>
          <h4>4.2 Data Portability</h4>
          <p>
            You have the right to request a copy of your personal data in a
            structured, machine-readable format.
          </p>
          <h4>4.3 Withdrawal of Consent</h4>
          <p>
            You can withdraw your consent to our use of your personal
            information at any time by deleting your account or contacting us
            directly.
          </p>
          <h3>5. Data Security</h3>
          <p>
            We implement appropriate technical and organizational measures to
            protect your personal data against unauthorized access, alteration,
            disclosure, or destruction. This includes using industry-standard
            encryption and secure data storage methods.
          </p>
          <h3>6. International Data Transfers</h3>
          <p>
            Your information may be transferred to and maintained on servers
            located outside of your state, province, country, or other
            governmental jurisdiction where data protection laws may differ from
            those of your jurisdiction. We take steps to ensure your data is
            protected in accordance with this privacy policy regardless of where
            it is processed.
          </p>
          <h3>7. Changes to This Privacy Policy</h3>
          <p>
            We may update our privacy policy from time to time. We will notify
            you of any changes by posting the new privacy policy on our website
            and updating the effective date. Your continued use of our services
            after the changes take effect signifies your acceptance of the
            updated policy.
          </p>
          <h3>8. Contact Us</h3>
          <p>
            If you have any questions or concerns about this privacy policy or
            our data practices, please contact us at Swellmail@gmail.com or
            236-869-2318.
          </p>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 SociAloha. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
